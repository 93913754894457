<template>
  <tr>
    <td>
      {{ user.fullname }} 
    </td>
    <td>
      {{ typeCompte }}
    </td>
        
    <td>
      <div class="d-flex">
        <a
          href="javascript:void(0);"
          title=""
          @click.prevent="switchingToUser"
        >
          <div
            class="circle"
            :class="isSwitchAccountToUser? 'active': ''"
          >
            <div
              class="checkmark"
              v-if="isSwitchAccountToUser"
            />
          </div>
          <!-- <i class="las la-pen" v-if="isSwitchAccountToUser"/>
                    <i class="las la-user" v-else/> -->
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        user: {type: Object, required: true}
    },
    methods: {
        ...mapMutations({
            setSwitchAccountTo: 'auth/SET_SWITCH_ACCOUNT_TO'
        }),
        switchingToUser(){
            this.setSwitchAccountTo(this.user)
        }
    },
    computed: {
        ...mapGetters({
            typeComptes: 'auth/typeComptes',
            switchAccountTo: 'auth/switchAccountTo'
        }),
        isSwitchAccountToUser(){
            if(this.switchAccountTo !== null) return this.switchAccountTo.uid === this.user.uid
            return false
        },
        typeCompte(){
            let t = this.typeComptes.find(item => item.uid === this.user.typeCompte)
            if(t) return t.libelle
            return '-'
        }
    }

}
</script>

<style lang="scss">


</style>